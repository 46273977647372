const dev = 'dev5';
const userDomain = 'angular5';
export const environment = {
	production: false,
	apiDomain: 'https://' + dev + '.api.astrotalk.in/AstroTalk/',
	kundliApi: 'https://' + dev + '.api.astrotalk.in/v1/',
	apiDomainSupport: 'https://' + dev + '.api.astrotalk.in/AstroChat/',
	chatApi: 'https://' + dev + '.api.astrotalk.in/AstrotalkChat/',
	dev1AstrotalkChat: 'https://' + dev + '.api.astrotalk.in/AstroChat/',
	astroMallApi: 'https://' + dev + '.api.astrotalk.in/AstroMall/',
	liveEventDomain: 'https://' + dev + '.api.astrotalk.in/AstrotalkLive/',
	astrologerList: 'https://' + dev + '.api.astrotalk.in/AstroTalk/',
	userDomain: 'https://'+ userDomain + '/astrotalk.in/',
	frontDomain: 'https://' + 'test' + '.astrologer.astrotalk.in/',
	apiPrefix: 'https://api.astrologer.com/astrologer/api/v1/',
	assetsUrl: 'https://api.astrologer.com/astrologer/',
	authKey: 'astrologer_dev_token',
	LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
	SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
	CODEYETI_RAZOR_PAY: 'rzp_test_K2FmWkyeoXIGD3',
	MASKYETI_RAZOR_PAY: 'rzp_test_mnLQsJF2nkRjsg',
	ASTROTALK_RAZOR_PAY: 'rzp_test_8z4JnWLwdNHC3e',
	PAYTMMID: 'aGuuxv80528402551811',
	PAYTMWEBSITE: 'WEBSTAGING',
	INDUSTRY_TYPE_ID: 'Retail',
	CHANNEL_ID: 'WEB',
	firebase: {
		apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
		authDomain: 'astroyeti-4bc02.firebaseapp.com',
		databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
		projectId: 'astroyeti-4bc02',
		storageBucket: 'astroyeti-4bc02.appspot.com',
		messagingSenderId: '135983970470',
		appId: '1:135983970470:web:704107c27bf32e2a351c67',
		measurementId: 'G-MB47Q5ZT36'
	}

	/*production: true,
  apiPrefix: 'https://api.astrologer.com/astrologer/api/v1/',
  assetsUrl: 'https://api.astrologer.com/astrologer/',
  authKey: 'astrologer_live_token',
	// apiDomain: 'http://65.1.228.141:8080/AstroTalk/', // pre-prod
  apiDomain: 'https://api.prod.astrotalk.in/AstroTalk/',
  kundliApi: 'https://api.kundali.astrotalk.com/v1/',
  chatApi: 'https://api.paidchat.astrotalk.com/AstrotalkChat/',
  dev1AstrotalkChat: 'https://api.paidchat.astrotalk.com/AstrotalkChat/',
  liveEventDomain: 'https://api.live.astrotalk.com/AstrotalkLive/',
  apiDomainSupport: 'https://api.supportchat.astrotalk.com/AstroChat/',
  LOCATIONAPIDOMAIN: 'https://api.locationiq.com/v1/',
  SECONDPLACES: 'pk.e8a0a377dd1df522d510716ba61eaeab',
  CODEYETI_RAZOR_PAY: 'rzp_live_nbFIyWp9PWCqNl',
  MASKYETI_RAZOR_PAY: 'rzp_live_idcMSOkCdvdxCB',
  ASTROTALK_RAZOR_PAY: 'rzp_live_Mw6ZYXea2k4yyj',
  astroMallApi: 'https://api.astromall.astrotalk.com/AstroMall/',
  PAYTMMID: 'CODEYE84459150770958',
  PAYTMWEBSITE: 'WEBPROD',
  INDUSTRY_TYPE_ID: 'Retail109',
  CHANNEL_ID: 'WAP',
	frontDomain: 'https://astrologer.astrotalk.com/',
	userDomain: 'https://astrotalk.com/',
  firebase: {
    apiKey: 'AIzaSyCwst9CLNKuA5UvPuxWdRV6nd-_fE8TF6E',
    authDomain: 'astroyeti-4bc02.firebaseapp.com',
    databaseURL: 'https://astroyeti-4bc02.firebaseio.com',
    projectId: 'astroyeti-4bc02',
    storageBucket: 'astroyeti-4bc02.appspot.com',
    messagingSenderId: '135983970470',
    appId: '1:135983970470:web:704107c27bf32e2a351c67',
    measurementId: 'G-MB47Q5ZT36'
  }*/
};
